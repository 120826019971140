import { theme } from '../../../styles'

export interface ILoader {
  color?: string
}

export function Loader({ color = theme.cssVars.white }: ILoader): JSX.Element {
  return (
    <>
      <div className="Loader flex">
        <span className="Loader__Circle" />
        <span className="Loader__Circle" />
        <span className="Loader__Circle" />
        <span className="Loader__Circle" />
      </div>

      <style jsx>{`
        .Loader__Circle {
          background-color: ${color};
        }
      `}</style>
      <style jsx>{`
        .Loader {
          height: 1rem;
          width: 7rem;
          position: relative;
        }
        .Loader__Circle {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          animation: move 500ms linear 0ms infinite;
          margin-right: 30px;
        }
        .Loader__Circle:first-child {
          position: absolute;
          top: 0;
          left: 0;
          animation: grow 500ms linear 0ms infinite;
        }

        .Loader__Circle:last-child {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: 0;
          animation: grow 500ms linear 0s infinite reverse;
        }

        @keyframes grow {
          from {
            transform: scale(0, 0);
            opacity: 0;
          }
          to {
            transform: scale(1, 1);
            opacity: 1;
          }
        }

        @keyframes move {
          from {
            transform: translateX(0px);
          }
          to {
            transform: translateX(45px);
          }
        }
      `}</style>
    </>
  )
}
