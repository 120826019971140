import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'

export function useBeforeOnLoad(handle: (e: BeforeUnloadEvent) => void, enable: boolean): void {
  const { events } = useRouter()

  const handleBeforeUnLoad = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!handle) {
        return
      }
      event?.preventDefault?.()
      handle(event)
      return
    },
    [handle],
  )

  useEffect(() => {
    if (enable) {
      events.on('routeChangeStart', handleBeforeUnLoad)

      //WARNING: Firefox does not cache pages with unload/beforeunload handlers
      window.addEventListener('beforeunload', handleBeforeUnLoad, { passive: true })
      return () => {
        events.off('routeChangeStart', handleBeforeUnLoad)
        window.removeEventListener('beforeunload', handleBeforeUnLoad)
      }
    }
  }, [enable, handle])
}
