import { useContext, useEffect, useState } from 'react'
import { Button } from '../components/atoms/Button'
import { TF1InfoLogo } from '../components/atoms/Icon/TF1InfoLogo'
import { PlayerVideo } from '../components/atoms/PlayerVideo'
import { TagCommander } from '../components/atoms/TagCommander'

import { TagCommanderContext } from '../contexts/tagcommander'
import { ExternalScripts } from '../contexts/ExternalScripts'
import { GigyaAuth } from '../contexts/GigyaAuth'

import { isServer } from '../helpers/client'
import tagcommander from '../vendors/tagcommander'
import gigya from '../vendors/gigya'
import { useTMSHitForHeader } from '../hook/useTMSClickHit'
import { fetchConfig } from '../api/client/ssr'
import { LCI_LIVE_TMS_DATA } from '../constants/tms'
import { VIDEO_LIVE_ID } from '../constants/components'
import { fontStyle, globalStyle, mixins } from '../styles'
import { Loader } from '../components/atoms/Loader'

const ContentPage404 = ({ isSetup }: { isSetup?: boolean }): JSX.Element => {
  const { isTagCommanderReady, hit } = useContext(TagCommanderContext)
  const { headerHit } = useTMSHitForHeader()

  useEffect(() => {
    if (isTagCommanderReady) {
      hit({})
    }
  }, [isTagCommanderReady])

  const arrowRight = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid="arrow-right">
      <path
        d="M5.03979 12.1133H19.0398"
        stroke="var(--color-deepBlue)"
        strokeWidth="2"
        strokeLinejoin="bevel"
      />
      <path
        d="M12.0398 5.11328L19.0398 12.1133L12.0398 19.1133"
        stroke="var(--color-deepBlue)"
        strokeWidth="2"
        strokeLinejoin="bevel"
      />
    </svg>
  )

  return (
    <>
      <div className="Page404">
        <a
          href="/"
          className="Page404__Logo"
          onClick={() => headerHit('logo')}
          title="Tf1 Info - Retour à l'accueil"
        >
          <TF1InfoLogo size={200} />
        </a>
        <div className="Page404__Content">
          <div className="Page404__Content__Player">
            {isSetup ? (
              <PlayerVideo
                containerId="page-404-id"
                videoId={VIDEO_LIVE_ID}
                isHiddenMobile
                withDirectBanner
              />
            ) : (
              <Loader />
            )}
          </div>
          <div className="Page404__Content__Info">
            <div className="Page404__Content__Text">
              <p>La page demandée n&#39;existe pas ou</p>
              <p>
                <strong>n&#39;est pas disponible à cette adresse.</strong>
              </p>
              <Button
                type="button"
                href="/"
                textColor="var(--color-deepBlue)"
                backgroundColor="var(--color-lightBlue)"
              >
                <span>Retourner à la page d&#39;accueil</span>
                {arrowRight}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <style jsx global>{`
        body {
          width: 100%;
          height: 100%;
          background: var(--color-deepBlue);
        }
      `}</style>

      <style jsx>{`
        .Page404 {
          display: flex;
          justify-content: center;
          align-content: center;
          flex-direction: column;

          width: 100%;
          min-height: 100vh;

          background: var(--color-deepBlue);
        }

        .Page404__Logo {
          height: 150px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
          align-items: baseline;
        }

        .Page404__Content {
          flex: 1;
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
          height: calc(100vh - 150px);
        }

        .Page404__Content__Player {
          width: 100%;
          display: flex;
          justify-content: center;
          align-self: center;
          flex: 3;
        }

        .Page404__Content__Player > :global(:where(.PlayerVideo, .Loader)) {
          display: flex;
          justify-content: center;
          align-self: center;

          margin: 0 70px 0;
        }

        .Page404__Content__Player > :global(.PlayerVideo) {
          width: 400px;
        }

        .Page404__Content__Info {
          flex: 2;
          color: var(--color-white);
          justify-content: center;
          align-self: center;
          display: flex;

          text-align: center;
          font-size: 18px;
          font-style: normal;
          line-height: 44px;
        }

        .Page404__Content__Text {
          width: 100%;
          margin: 30px 20px 0;
        }

        p {
          font-weight: 200;
        }

        strong {
          font-weight: 700;
        }

        .Page404__Content__Text > :global(.Button) {
          margin: 20px 0px;
          display: flex;
          align-items: center;
        }

        span {
          margin-top: 3px;

          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 100px;
          text-align: center;
        }

        @media ${mixins.mediaQuery.desktop} {
          .Page404__Content {
            flex-direction: row;
          }

          .Page404__Content__Text {
            width: 400px;
            margin: 30px 20px 0 -100px;
          }

          .Page404__Content__Player > :global(.PlayerVideo) {
            display: flex;
            justify-content: center;
            align-self: center;

            width: 700px;
            margin: 0 70px 0;
          }

          .Page404__Content__Info {
            font-size: 36px;
          }

          .Page404__Content__Text > :global(.Button) {
            margin-top: 60px;
          }

          span {
            font-size: 20px;
          }
        }
      `}</style>
    </>
  )
}

export const Page404 = (): JSX.Element => {
  const [config, setConfig] = useState(null)

  const updateConfig = async () => {
    const publicConfig = await fetchConfig()
    setConfig(publicConfig)
  }
  useEffect(() => {
    if (!isServer()) {
      updateConfig()
    }
  }, [])

  return (
    <>
      {config ? (
        <ExternalScripts
          scripts={[
            {
              ...tagcommander,
              src: config?.tagcommander.url,

              // Prevent filtering in ExternalScripts because thoses scripts used to be loaded in _document.tsx
              isBeforeInteraction: false,
            },
            {
              ...gigya,
              src: `https://cdns.eu1.gigya.com/js/gigya.js?apikey=${config?.gigya.apiKey}&lang=fr`,
              isBeforeInteraction: false,
            },
          ]}
        >
          <GigyaAuth>
            <TagCommander
              tms={{
                page: {
                  page_pageName: `erreur-404`,
                  screen_screenType: 'page-erreur',
                  medium_context_amp: 'false',
                  medium_context_syndication: 'false',
                  medium_environment: config.environment,
                  medium_mediumName: 'tf1info',
                  medium_platform: 'website',
                },
                videos: {
                  [VIDEO_LIVE_ID]: LCI_LIVE_TMS_DATA,
                },
              }}
            >
              <ContentPage404 isSetup />
            </TagCommander>
          </GigyaAuth>
        </ExternalScripts>
      ) : (
        <ContentPage404 />
      )}
      <style jsx global>
        {fontStyle}
      </style>
      <style jsx global>
        {globalStyle}
      </style>
    </>
  )
}

Page404.isStatic = true

export default Page404
